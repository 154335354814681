<template>
  <v-container>
    <v-row>
      <v-btn color="blue-grey lighten-3"
        depressed
        @click="dialogTest = true">TEST GEOFENCE</v-btn>
      <v-spacer />
      <!-- <v-btn color="blue-grey lighten-3"
        depressed
        @click="dialogTestByMSISDN = true">TEST MESSAGE</v-btn> -->
      <v-spacer />
      <v-btn color="red darken-3"
        outlined
        :to="{ name: 'automateCondition' }">CANCEL</v-btn>
      <v-btn class="mx-5"
        color="light-blue darken-1"
        outlined
        @click.prevent="createGeo">CREATE</v-btn>
      <v-dialog
        v-model="dialogTest"
        width="500">
        <v-card>
          <v-card-title>Push Message Test Geofence</v-card-title>
          <v-divider />
          <v-card-text>
            <v-row class="mt-5">
              <v-text-field
                v-model="msisdn"
                label="DTAC MSISDN"
                outlined
                dense
                required
                hint="Format Phone No is '66XXXXXXXXX' not '0XXXXXXXXX'"
                :rules="[rules.phoneFormat]"
                persistent-hint />
              <v-text-field
                class="mt-5 mr-5"
                v-model.number="jobInfo.latitude"
                label="LATITUDE"
                outlined
                dense
                required
                persistent-hint />
              <v-text-field
                class="mt-5"
                v-model.number="jobInfo.longitude"
                label="LONGITUDE"
                outlined
                dense
                required
                persistent-hint />
            </v-row>
          </v-card-text>
          <v-divider />
          <v-card-actions>
            <v-spacer />
            <v-btn  class="mx-5"
              color="red darken-3"
              outlined
              @click="dialogTest = false">CANCEL</v-btn>
            <v-btn
              color="light-blue darken-1"
              outlined
              @click="createJobTestGeo">TEST PUSH MESSAGE GEOFENCE</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: 'btnJobGeoAction',
  props: {
    jobInfo: {
      type: Object
    }
  },
  data: () => ({
    dialogTest: false,
    dialogTestByMSISDN: false,
    msisdn: '',
  }),

  // watch: {
  //   msisdn(val) {
  //     const msisdnEstimate = val.split(',');
  //     this.jobInfo.msisdn = msisdnEstimate;
  //   }
  // },

  methods: {
    createGeo() {
      // eslint-disable-next-line no-undef
      eventBus.$emit('createGeo', this.jobInfo);
    },

    createJobTestGeo() {
      const msisdnEstimate = this.msisdn.split(',');
      this.jobInfo.msisdn = msisdnEstimate;
      // eslint-disable-next-line no-undef
      eventBus.$emit('createJobTestGeo', this.jobInfo);
    },

    // update() {
    //   // eslint-disable-next-line no-undef
    //   eventBus.$emit('updateCard', this.card);
    // },

    cancel() {
      // eslint-disable-next-line no-undef
      eventBus.$emit('closeSingleForm', false);
    }
  }
};
</script>
